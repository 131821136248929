:root {
  --main-text-color: #404040;
  --desktop-w: 45vw;
  --mobile-w: 50vh;
}

* {
  box-sizing: border-box;
}

a,
a:active,
a:hover,
a:focus {
  user-select: none;
  color: inherit;
  text-decoration: none;
}

h1,
ul,
dd,
dt,
dl,
p {
  margin: 0;
  padding: 0;
}

dl {
  margin-left: 20px;
}

dd,
dt {
  font-weight: 400;
}

dd {
  padding: 1px 0;
}

dd a:hover {
  background-color: white;
}

dt {
  margin-bottom: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

h1,
p {
  position: relative;
  margin: 20px 0 0 20px;
  padding: 0 0 8px 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--main-text-color);
}

body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: #f0f0f0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.05em;
}

header,
footer {
  height: 10%;
}

footer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

footer p {
  margin-bottom: 20px;
}

section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row-reverse;
  height: 80%;
}

article {
  position: relative;
}

canvas {
  user-select: none;
}

canvas.underline {
  position: absolute;
  left: 0;
  bottom: 0;
  user-select: none;
}
